/* eslint-disable no-param-reassign */
import axios from 'axios';
import storage from './storage';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use((config) => {
  const url = config.url || '';

  if (!url.endsWith('login')) {
    const token = storage.getToken();

    if (token && token.length > 0) { config.headers.Authorization = `Bearer ${token}`; }
  }

  return config;
});

export default api;
