import React from 'react';
import { FooterContainer } from './styles';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <FooterContainer>
        {currentYear}
        {' '}
        © Pontual Cargo
      </FooterContainer>
    </>
  );
};

export default Footer;
