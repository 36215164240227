import styled from 'styled-components';
import logo from './assets/logo_pontual.png';
import logoDev from './assets/logo_pontual_dev.png';

interface IsProdProps {
  readonly isProd: boolean;
}

export const HeaderContainer = styled.header<IsProdProps>((props) => ({
  position: 'fixed',
  left: 0,
  top: 0,
  width: '100%',
  backgroundImage: props.isProd ? `url(${logo})` : `url(${logoDev})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  textAlign: 'center',
  height: '60px',
}));

export const TopBar = styled.div({
  backgroundColor: 'white',
  boxShadow: 'none',
  borderBottom: '1px solid #cdccdb',
  left: 0,
  right: 0,
  top: 0,
  position: 'fixed',
  zIndex: 999,
  height: '60px',
});
