import React, { useEffect } from 'react';
import Quagga, { QuaggaJSResultObject } from '@ericblade/quagga2';

import { Container, Video } from './styles';

const Scanner : React.FC = () => {
  const onDetected = (result: QuaggaJSResultObject) => {
    const isbn = result.codeResult.code;

    // eslint-disable-next-line no-alert
    alert(isbn);
  };

  useEffect(() => {
    if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
      Quagga.init({
        inputStream: {
          name: 'Live',
          type: 'LiveStream',
          target: '#video',
        },
        decoder: {
          readers: ['ean_reader'],
        },
      }, (err) => {
        if (err) {
          return;
        }
        Quagga.start();
      });

      Quagga.onDetected(onDetected);
    }
  }, []);

  return (
    <>
      <Video id="video" />
      <Container id="container">
        {/* <img id="marker" src="../../../assets/barcode.svg" alt="" /> */}
        <p id="label">Aponte para um código de barras</p>
      </Container>
    </>
  );
};

export default Scanner;
