import jwt from 'jsonwebtoken';
import { Token } from '../interfaces/Token';

const setToken = (token: string): void => {
  localStorage.setItem('token', token);
};

const clearToken = (): void => {
  localStorage.removeItem('token');
};

const getTokenDecoded = (token: string): Token => {
  const decoded = jwt.decode(token || '', { complete: true });

  return decoded as Token;
};

const isTokenExpired = (token: string): boolean => {
  let hasTokenExpired = false;

  jwt.verify(token, '', (err) => {
    const erro = err || { name: '' };
    hasTokenExpired = erro.name === 'TokenExpiredError';
  });

  return hasTokenExpired;
};

const getToken = (): string => {
  let token = localStorage.getItem('token') || '';

  if (isTokenExpired(token)) {
    clearToken();
    token = '';
  }

  return token;
};

const isAuthenticated = (): boolean => {
  const token = getToken();

  return !!token;
};

export default {
  getToken,
  setToken,
  clearToken,
  isAuthenticated,
  getTokenDecoded,
};
