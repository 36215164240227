import styled from 'styled-components';

export const Video = styled.div({
  video: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
  },
  canvas: {
    display: 'none',
  },
});

export const Container = styled.div({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  minWidth: '100%',
  minHeight: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '#label': {
    color: '#FFF',
  },
});
