import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import storage from '../../services/storage';

export interface ProtectedRouteProps extends RouteProps {
  component: React.FC
  path: string
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props: ProtectedRouteProps) => {
  const { component: Component, path } = props;

  const isAuthenticated = storage.isAuthenticated();

  if (!isAuthenticated) {
    return (
      <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    );
  }

  return (
    <Route path={path}>
      <Component />
    </Route>
  );
};

export default ProtectedRoute;
