import styled from 'styled-components';

export const FooterContainer = styled.footer({
  bottom: 0,
  left: 0,
  color: '#58666E',
  fontSize: '14px',
  position: 'fixed',
  padding: '10px 0',
  width: '100%',
  textAlign: 'center',
  borderTop: '1px solid rgba(0, 0, 0, 0.1)',
});

export const Wrapper = styled.div({
  width: '100%',
});
