import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../pages/Footer';
import Header from '../pages/Header';
import Home from '../pages/Home';
import Login from '../pages/Login';
// import NotFound from '../pages/NotFound';
import Scanner from '../pages/Scanner';
import ProtectedRoute from './ProtectedRoute';

const Routes: React.FC = () => (
  <>
    <Router>
      <Header />
      <Switch>
        <ProtectedRoute exact path="/" component={Home} />
        <ProtectedRoute path="/scanner" component={Scanner} />
        <Route path="/login" component={Login} />
        {/* <Route component={NotFound} /> */}
      </Switch>
      <ToastContainer />
      <Footer />
    </Router>
  </>
);

export default Routes;
