import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoginResponse } from '../../interfaces/LoginResponse';
import api from '../../services/api';
import notification from '../../services/notification';
import storage from '../../services/storage';

import {
  Wrapper, Heading, LoginTitle, LoginForm,
  FormGroup, Input, Button,
} from './styles';

const Login: React.FC = () => {
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    if (!username || !password) {
      notification.warn('Necessário informar usuário e senha.');
      return;
    }

    try {
      const response = await api.post('api/auth/login', { username, password }).then<LoginResponse>((r) => r.data);
      storage.setToken(response.token);
      history.push('/');
    } catch (err) {
      if (err.response.status === 401) {
        notification.warn(err.response.data.message);
      } else {
        notification.warn('Ocorreu um erro inesperado ao tentar realizar autenticação.');
      }
    }
  };

  return (
    <Wrapper>
      <Heading>
        <LoginTitle>Autenticação</LoginTitle>
      </Heading>
      <LoginForm>
        <FormGroup>
          <Input type="text" name="username" id="username" onChange={(event) => setUsername(event.target.value)} />
        </FormGroup>
        <FormGroup>
          <Input type="password" name="password" id="password" onChange={(event) => setPassword(event.target.value)} />
        </FormGroup>
        <FormGroup>
          <Button type="button" onClick={handleLogin}>Autenticar-se</Button>
        </FormGroup>
      </LoginForm>
    </Wrapper>
  );
};

export default Login;
