import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';
import api from '../../services/api';

const Home: React.FC = () => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [url, setUrl] = useState('');
  const [token, setToken] = useState('');

  const uploadImage = async () => {
    const gedInfo = { gedId: null, maxFileSize: 0, acceptedFiles: ['png', 'jpeg', 'jpg'] };
    const synctoken = await api.post('ged/synctoken', { gedInfo }, { withCredentials: true });
    setToken(synctoken.data);
  };

  const login = async () => {
    const postData = { Login: 'alan', Senha: '7365cSyRYLAUx6M' };
    api.post('autenticacao/login', { postData }, { withCredentials: true }).then(((response) => {
      // eslint-disable-next-line no-console
      console.log(response.headers['set-cookie']);
    }));
  };

  const check = async () => {
    const formData = new FormData();
    formData.append('file', selectedFile as Blob);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        GedUploadSyncToken: token,
        withCredentials: true,
      },
    };

    await api.post('ged/upload', formData, config);
  };

  const changeFile = (files: FileList | null) => {
    if (!files) return;

    const file = files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setUrl(reader.result as string);
    };

    setSelectedFile(file);
  };

  return (
    <>
      <Container>
        Olá, Visitante!
      </Container>
      <br />
      <input type="file" accept="image/x-png,image/jpeg,image/gif" onChange={(event) => changeFile(event.target.files)} />
      <br />
      <img alt="" src={url} />
      <br />
      <br />
      <button type="button" onClick={() => login()}>Login</button>
      <br />
      <br />
      <button type="button" onClick={() => uploadImage()}>Sync</button>
      <br />
      <br />
      <button type="button" onClick={() => check()}>Upload</button>
      <br />
      <br />
      <Link to="/scanner">Scanner</Link>
    </>
  );
};

export default Home;
