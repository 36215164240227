import styled from 'styled-components';

export const Wrapper = styled.div({
  width: '368px',
  minHeight: '100%',
  padding: '40px 30px',
  borderRadius: '4px',
  backgroundColor: '#ffffff',
  margin: '0 auto',
  position: 'relative',
  alignContent: 'center',
});

export const Heading = styled.div({
  textAlign: 'center',
  border: 'none',
  padding: '10px 20px',
  paddingBottom: '44px',
});

export const LoginTitle = styled.span({
  fontSize: '36px',
  fontWeight: 500,
  fontStyle: 'normal',
  fontStretch: 'normal',
  lineHeight: 1.19,
  textAlign: 'center',
  color: '#1c1862',
  top: '24px',
  paddingBottom: '16px',
  borderBottom: '4px solid #c6142d',
  width: 'max-content',
});

export const LoginForm = styled.form({

});

export const FormGroup = styled.div({
  width: '100%',
  margin: '16px 0',
  padding: '0px',
});

export const Input = styled.input({
  borderRadius: '3px',
  backgroundColor: '#ffffff !important',
  border: 'solid 1px #cdccdb',
  color: '#4a4a4a !important',
  width: '100%',
  height: '36px',
  padding: '7px 12px',
});

export const Button = styled.button({
  height: '35px',
  borderRadius: '4px',
  backgroundColor: '#34348c',
  fontSize: '14px',
  fontWeight: 500,
  fontStyle: 'normal',
  fontStretch: 'normal',
  textAlign: 'center',
  color: '#ffffff',
  padding: '0px 0px 0px 0px',
  border: '0px',
  width: '100%',
});
