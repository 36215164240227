import React from 'react';
// import { useHistory } from 'react-router-dom';
// import storage from '../../services/storage';
// const history = useHistory();

// const handleLogout = () => {
//   storage.clearToken();
//   history.push('/login');
// };
import { HeaderContainer, TopBar } from './styles';

const Header: React.FC = () => (
  <>
    <TopBar>
      <HeaderContainer isProd={process.env.REACT_APP_ENVIRONMENT === 'production'} />
    </TopBar>
  </>
);

export default Header;
